@import "./../required";

.slider {
    position: relative;
}

.slider-inner {
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    scrollbar-width: none;
    -webkit-overflow-scrolling: touch;

    &.row {
        flex-wrap: nowrap;
    }

    &::-webkit-scrollbar {
        display: none;
    }
}

.slider-item {
    scroll-snap-align: start;
}

.slider-item-thumb {
    flex: 1 0 20%;
    max-width: 64px;
    margin-right: map-get($spacers, 2);
    cursor: pointer;
}

.slider-control {
    position: absolute;
    top: 50%;
    z-index: 1;
    width: 50px;
    height: 36px;
    padding: 0;
    background-color: rgba($gray-200, 0.8);
    border: 0;
    border-radius: $border-radius-sm;
    transition: $carousel-control-transition;
    transform: translateY(-50%);

    .icon-lg {
        stroke: $gray-900;
        stroke-width: .5;
    }
}
.slider-control-prev {
    left: 0;
}
.slider-control-next {
    right: 0;
}

.slider-indicators {
    position: absolute;
    bottom: calc($grid-gutter-width / 2);
    left: 50%;
    display: flex;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    transform: translateX(-50%);

    li {
        opacity: .5;

        &.active {
            opacity: 1;
        }
    }

    a,
    button {
        display: inline-block;
        width: 30px;
        height: 4px;
        overflow: hidden;
        text-indent: -999px;
        vertical-align: middle;
        background: $gray-700;
    }

    button {
        border: 0;
    }
}

.slider-indicators-bullets {
    max-width: 65%;
    overflow: auto;

    &::-webkit-scrollbar {
        display: none;
    }

    a,
    button {
        width: 14px;
        height: 14px;
        border: $border-width solid $white;
        border-radius: 50%;
    }

    li.active a,
    li.active button {
        background-color: $primary;
    }

    li + li {
        margin-left: 10px;
    }
}

.slider-remove-snap {
    scroll-snap-type: unset;
}

.slider-partial {
    .slider-inner {
        padding-right: $grid-gutter-width * 3;
        scroll-padding: calc((#{$grid-gutter-width} * 3) / 2);
    }

    .slider-item:last-child {
        margin-right: -($grid-gutter-width * 3);
    }
}

.slider-controls-on-hover {
    .slider-control {
        pointer-events: none;
        opacity: 0;
    }

    &:hover {
        .slider-control {
            pointer-events: unset;
            opacity: $carousel-control-hover-opacity;
        }
    }
}
